<template>
  <section v-if="listLength < 1">
    <h2 class="p-12 text-2xl font-light">
      {{ message }}
    </h2>
  </section>
</template>

<script>
export default {
  props: ["listLength", "message"],
};
</script>

<style></style>
