<template>
  <div class="w-full mt-24">
    <the-top-bar :icon="'fas fa-clipboard'" :title="'Porudzbine'"></the-top-bar>
    <div class="px-9 pt-4 pb-5">
      <div
        class="w-full flex flex-row items-center justify-start py-4 border-b border-gray-200"
      >
        <div class="flex items-center py-0 px-4">
          <p class="text-base text-gray-600 dark:text-gray-400" id="page-view">
            {{ startPosition + 1 }} -
            {{
              endPosition > allOrders.length ? allOrders.length : endPosition
            }}
            od {{ allOrders.length }}
          </p>
          <a
            class="text-gray-600 dark:text-gray-400 ml-2 border-transparent border cursor-pointer rounded"
            @click="showBefore"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="15 6 9 12 15 18" />
            </svg>
          </a>
          <a
            class="text-gray-600 dark:text-gray-400 border-transparent border rounded focus:outline-none cursor-pointer"
            @click="showNext"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-right"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="9 6 15 12 9 18" />
            </svg>
          </a>
        </div>
        <div
          class="flex items-center px-4 py-2 rounded-xl mx-2 border border-gray-400"
        >
          <i class="fas fa-search mr-4 text-gray-400"></i>
          <input
            type="text"
            v-model="search"
            placeholder="pretraga..."
            class="text-base outline-none capitalize"
          />
        </div>
      </div>
      <table class="w-full whitespace-nowrap" v-if="this.allOrders.length > 0">
        <thead class="sticky top-116 bg-white z-30">
          <tr
            tabindex="0"
            class="focus:outline-none h-16 w-full text-sm leading-none text-gray-800"
          >
            <th class="font-normal text-left pl-4">ID</th>
            <th class="font-normal text-left pl-4">Jedinstveni kod</th>
            <th class="font-normal text-left pl-12">Korisnik</th>
            <th class="font-normal text-left pl-20">Total</th>
            <th class="font-normal text-left pl-20">Poruceno</th>
            <th class="font-normal text-left pl-20 text-transparent">
              Poruceno
            </th>
          </tr>
        </thead>
        <tbody class="w-full">
          <the-order
            v-for="order in allOrders.slice(
              this.startPosition,
              this.endPosition
            )"
            :key="order.id"
            :id="order.id"
            :orderid="order.orderid"
            :userid="order.userid"
            :total="order.orderTotal"
            :totalProducts="order.orderProductTotal"
            :userEmail="order.userEmail"
            :date="order.orderDate"
            :url="order.url"
          ></the-order>
        </tbody>
      </table>
      <!-- <div class="bg-white shadow px-9 pt-4 mt-20 pb-5 overflow-y-auto"></div>
    </div> -->
      <no-content
        :listLength="this.allOrders.length"
        :message="'Nema novih porudzbina.'"
      ></no-content>
    </div>
  </div>
</template>

<script>
import NoContent from "../partials/NoContent.vue";
import TheTopBar from "../partials/TheTopBar.vue";
import TheOrder from "../partials/TheOrder.vue";
export default {
  components: { NoContent, TheTopBar, TheOrder },
  data() {
    return {
      show: null,
      userEmail: "",
      search: "",
      startPosition: 0,
      endPosition: 9,
    };
  },
  methods: {
    async getAllOrders() {
      await this.$store.dispatch("getAllOrders");
    },
    showBefore() {
      if (this.startPosition < 1) {
        return;
      }
      this.startPosition -= 9;
      this.endPosition -= 9;
    },
    showNext() {
      if (this.endPosition > this.allOrders.length || this.endPosition == this.allOrders.length) {
        return;
      }
      this.startPosition = this.endPosition;
      this.endPosition += 9;
    },
  },
  computed: {
    allOrders() {
      if (this.search == "") {
        return this.$store.getters.get_allOrders;
      }
      return this.$store.getters.get_allOrders.filter(
        (order) =>
          order.orderid.toLowerCase().includes(this.search.toLowerCase()) ||
          order.userEmail.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  mounted() {
    this.getAllOrders();
  },
};
</script>

<style lang="scss"></style>
