<template>
  <tr
    tabindex="0"
    class="focus:outline-none h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100"
  >
    <td class="pl-4">
      <p class="text-sm font-medium leading-none text-gray-800">
        {{ this.id }}
      </p>
    </td>
    <td class="pl-4 cursor-pointer">
      <div class="flex items-center">
        <div class="pl-4">
          <p class="font-medium">{{ this.orderid }}</p>
        </div>
      </div>
    </td>

    <td class="pl-12">
      <p class="font-medium">{{ this.userEmail }}</p>
      <p class="text-xs leading-3 text-gray-600 mt-2">
        id korisnika: {{ this.userid }}
      </p>
    </td>
    <td class="pl-20">
      <p class="font-medium">{{ this.total }},00 rsd</p>
      <p class="text-xs leading-3 text-gray-600 mt-2">
        bez dostave: {{ this.totalProducts }},00 rsd
      </p>
    </td>
    <td class="pl-20">
      <p class="font-medium">{{ this.date }}</p>
    </td>
    <td class="pl-16">
      <div class="flex justify-center">
        <button
          @click="generatePdf"
          v-if="!this.url.includes($store.getters.get_pdf)"
          class="button"
        >
          <span class="text"> gnerisi PDF </span>
        </button>
        <a
          v-if="this.url.includes($store.getters.get_pdf)"
          :href="$store.getters.get_API + '/pdf/' + this.url"
          :download="this.url"
          target="_blank"
          class="button"
        >
          <span class="text">skini file </span>
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: [
    "orderid",
    "userid",
    "id",
    "totalProducts",
    "total",
    "url",
    "date",
    "userEmail",
  ],

  methods: {
    async generatePdf() {
      await this.$store.dispatch("getOrderPdf", { id: this.orderid });
    },
  },
};
</script>

<style lang="scss">
.button {
  @apply flex items-center justify-center py-4 px-7 focus:outline-none bg-white border border-yellow rounded hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700;
  .text {
    @apply text-sm font-medium text-center text-yellow capitalize;
  }
}
</style>
